<template>
  <b-card>
    <b-container>
      <validation-observer :slim="true" ref="OTPValidation">
        <div>
          <div
            style="display: flex; flex-direction: column; justify-items: center; align-items: center"
          >
            <img :src="paperless" class="warranty-logo" style="width: 100%;" />
            <div
              class="mb-2 font-weight-bold text-center"
              style="font-size: 2vw"
            >
              Login with One Time Password (OTP)
            </div>
          </div>

          <div class="section-data">
            <h5>Email Verification</h5>
            <p style="font-weight:bold; color:red;">
              Note: Please enter your email to receive an OTP for email
              validation
            </p>
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
              tag="div"
              class="form-line"
            >
              <label>Email</label>

              <div class="form-validation-input">
                <b-col class="no-gutters">
                  <b-row>
                    <b-col style="padding-left:0" cols="8">
                      <b-form-input
                        placeholder="Enter Email"
                        v-model="form.email"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                    <b-col cols="4"
                      ><b-button
                        class="button-otp"
                        variant="primary"
                        @click="sendOTP"
                        >Request OTP</b-button
                      ></b-col
                    >
                  </b-row>
                </b-col>
              </div>
            </validation-provider>
            <div><p></p></div>

            <div>
              <p style="font-weight:bold">
                If you did not received the OTP in 30 seconds, please click
                <a href="#" @click="sendOTP"><u>Resend OTP</u></a>
              </p>
            </div>

            <validation-provider
              tag="div"
              name="otp"
              class="form-line"
              rules="digits:4"
              #default="{ errors }"
            >
              <label>OTP</label>

              <div class="form-validation-input">
                <b-col class="no-gutters">
                  <b-row>
                    <b-col cols="8" style="padding-left:0">
                      <b-form-input
                        placeholder="Enter OTP"
                        v-model="submit.otp"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-input>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                    <b-col cols="4"
                      ><b-button
                        class="button-otp"
                        variant="primary"
                        @click="verify"
                        >Submit OTP</b-button
                      ></b-col
                    >
                  </b-row>
                </b-col>
              </div>
            </validation-provider>

            <validation-provider tag="div" class="form-line">
              <!-- <div style="width:74%; text-left:left;">
                
                <b-button style="margin: 1% 2% 2% 2%" @click="resend">resend</b-button>
                <b-button style="margin: 1% 0% 2% 2%" @click="verify">verify</b-button>
              </div> -->
            </validation-provider>
          </div>
          <!-- <b-btn class="float-right" :disabled="pass" variant="primary" @click="toListWarranty">next</b-btn> -->
        </div>
      </validation-observer>
    </b-container>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import axios from "@/axios";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      paperless: require("@/assets/images/drcc/daikin-paperless.png"),
      pass: true,
      form: {
        email: "",
      },
      submit: { otp: "" },
    };
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    sendOTP() {
      let lowerCaseEmail = this.form.email.toLowerCase().trim();
      this.form.email = lowerCaseEmail;
      axios
        .post("sales-and-purchase/drcc/send-otp", this.form)
        .then((response) => {
          this.$bvToast.toast(
            `request OTP has been sent to ${this.form.email}`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((e) => {
          console.log({ e });
          this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    resend() {
      let lowerCaseEmail = this.form.email.toLowerCase().trim();
      this.form.email = lowerCaseEmail;
      axios
        .post("sales-and-purchase/drcc/resend-otp", this.form)
        .then((response) => {
          this.$bvToast.toast(
            `request for resend OTP has been sent to ${this.form.email}`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((e) => {
          this.$bvToast.toast(
            `Failed to resend an OTP, ${e.response.data.errors[0].message}`,
            {
              title: "Failed",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
    verify() {
      let lowerCaseEmail = this.form.email.toLowerCase().trim();
      this.form.email = lowerCaseEmail;
      let toSend = { email: this.form.email, otp: this.submit.otp };
      this.emailLogin = this.form.email;
      axios
        .post("sales-and-purchase/drcc/verify-otp", toSend)
        .then(async (response) => {
          // this.pass=false;
          // console.log('computed email',this.emailLogin)
          // console.log(response)
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await this.sleep(4000);
          this.toListWarranty();
        })
        .catch((err) => {
          console.log({ err });
          if (err.request.status === 400) {
            this.$bvToast.toast(`${err.response.data.errors[0].message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(`${err.response.data.message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    toListWarranty() {
      this.$router.push(`/list-warranty`);
    },
  },
  computed: {
    emailLogin: {
      get() {
        return this.$store.getters["warranty/getEmail"];
      },
      set(payload) {
        return this.$store.dispatch("warranty/setEmail", payload);
      },
    },
    // ...mapState({
    //   emailLogin: (state) => {
    //     return state.warranty.email
    //   }
    // }),
  },
};
</script>

<style scoped>
.form-line {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
}

.form-line-otp {
  margin: 0 0 10px 0;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-validation-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-validation-input-otp {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 0 0 20px 0;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}
@media only screen and (max-width: 500px) {
  .form-line label:first-child {
    margin: 0 0 5px 0;
    width: 100%;
  }

  .form-line {
    flex-direction: column;
  }

  .warranty-logo {
    width: 100%;
    height: auto;
  }

  .button-otp {
    padding: 10px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 500px) {
  .form-line label:first-child {
    margin: 0;
    width: 15%;
  }

  .form-line input {
    width: 100%;
  }

  .form-line select {
    width: 100%;
  }
}
</style>
